import React, { useEffect, useState } from "react";
import moment from "moment";
import toast from "react-hot-toast";
import ReactPaginate from "react-paginate";
import FileSaver from "file-saver";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { listShopCustomerService } from "../../../Services/ApiServices";
import Styles from "./BrochureUser.module.css";
import Loader from "../../../SharedComponents/Loader/Loader";
import downloadIcon from "../../../Assets/download_icon.png";
import searchIcon from "../../../Assets/searchFilter.png";
import LeftArrow from "../../../Assets/modal_left_arrow.png";
import RightArrow from "../../../Assets/modal_right_arrow.png";

export default function BrochureUser() {
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [filterData, setFilterData] = useState({
    name: "",
    email: "",
    mobile_no: "",
    fromDate: "",
    toDate: "",
  });
  useEffect(() => {
    handleListShopCustomer();
  }, []);

  const handleListShopCustomer = (pageNumber = 1, csv, reset) => {
    setIsLoader(true);
    let formData = new FormData();
    formData.append(
      "name",
      !reset && filterData.name !== "" ? filterData.name : ""
    );
    formData.append(
      "email",
      !reset && filterData.email !== "" ? filterData.email : ""
    );
    formData.append(
      "mobile_no",
      !reset && filterData.mobile_no !== "" ? filterData.mobile_no : ""
    );
    if (filterData.fromDate) {
      formData.append(
        "from_date",
        dayjs(filterData.fromDate).format("YYYY-MM-DD")
      );
    }
    if (filterData.toDate) {
      formData.append("to_date", dayjs(filterData.toDate).format("YYYY-MM-DD"));
    }
    if (csv !== undefined) {
      formData.append("download", parseInt(csv));
    }
    listShopCustomerService(formData, pageNumber, 10)
      .then((res) => {
        if (csv !== undefined) {
          let url = res.data;
          let urlArr = url.split(".");
          let ext = urlArr[urlArr.length - 1];
          let dateTime =
            moment().format("DD-MM-YYYY") + "," + moment().format("hh:mm:ss a");
          let fileName = dateTime + "." + ext;
          FileSaver.saveAs(url, fileName);
        } else {
          setTableData(res.data);
          setPage(pageNumber - 1);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong!!");
        }
      })
      .finally(() => setIsLoader(false));
  };

  const handleReset = () => {
    setIsLoader(true);
    setFilterData({
      name: "",
      email: "",
      mobile_no: "",
    });
    handleListShopCustomer(1, undefined, true);
  };

  return (
    <div className={Styles.parentContainer + " container-fluid"}>
      <Loader isLoader={isLoader} />

      <div className={Styles.childContainerParent}>
        <div className={Styles.childContainer}>
          <div className={Styles.searchParentContainer}>
            <p className="fontBold align-self-center">
              Brochure <span className="orange">User</span>
            </p>
            <div>
              <img
                className={Styles.searchIcon}
                src={searchIcon}
                alt="search"
                onClick={() => setShowFilter(!showFilter)}
              />
              <button
                className={Styles.CsvBtn + " kstoreFont"}
                onClick={() => handleListShopCustomer(1, 1)}
              >
                Export to Csv
                <img
                  className={Styles.download}
                  src={downloadIcon}
                  alt="download"
                />
              </button>
            </div>
          </div>

          {showFilter ? (
            <div className={Styles.filterParentContainer + " row"}>
              <div className={Styles.categories + " col-md-3 col-sm-12"}>
                <label className="label">Name</label>
                <input
                  type="text"
                  placeholder="Name"
                  className={Styles.filterInput + " contentFont"}
                  value={filterData.name}
                  onChange={(e) =>
                    setFilterData({
                      ...filterData,
                      name: e.target.value,
                    })
                  }
                />
              </div>
              {/* <div className={Styles.categories + " col-md-3 col-sm-12"}>
                <label className="label">Email</label>
                <input
                  type="text"
                  placeholder="Email"
                  className={Styles.filterInput + " contentFont"}
                  value={filterData.email}
                  onChange={(e) =>
                    setFilterData({
                      ...filterData,
                      email: e.target.value,
                    })
                  }
                />
              </div> */}
              <div className={Styles.categories + " col-md-3 col-sm-12"}>
                <label className="label">Mobile Number</label>
                <input
                  type="text"
                  placeholder="Mobile Number"
                  className={Styles.filterInput + " contentFont"}
                  value={filterData.mobile_no}
                  maxLength={10}
                  onKeyDown={(e) => {
                    if (
                      e.key === "0" ||
                      parseInt(e.key) ||
                      e.key === "Backspace"
                    ) {
                    } else {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) =>
                    setFilterData({
                      ...filterData,
                      mobile_no: e.target.value,
                    })
                  }
                />
              </div>

              <div className={Styles.categories + " col-md-3 col-sm-12"}>
                <label className="label">From Date</label>
                <DatePicker
                  value={filterData.fromDate}
                  className={Styles.filterInput + " contentFont"}
                  format={"DD/MM/YYYY"}
                  onChange={(val) => {
                    setFilterData({
                      ...filterData,
                      fromDate: val,
                    });
                    if (val > filterData.toDate) {
                      setFilterData((pre) => ({ ...pre, toDate: "" }));
                    }
                  }}
                />
              </div>
              <div className={Styles.categories + " col-md-3 col-sm-12"}>
                <label className="label">To Date</label>
                <DatePicker
                  value={filterData.toDate}
                  className={Styles.filterInput + " contentFont"}
                  format={"DD/MM/YYYY"}
                  disabledDate={(current) => {
                    return current < filterData.fromDate;
                  }}
                  onChange={(val) => {
                    setFilterData({
                      ...filterData,
                      toDate: val,
                    });
                  }}
                />
              </div>
              <div
                className={
                  Styles.searchBtnParent + " col-md-3 col-sm-12 align-self-end"
                }
              >
                <button
                  onClick={() => {
                    handleReset();
                  }}
                  className={Styles.resetBtn + " me-2"}
                >
                  Reset
                </button>
                <button
                  onClick={() => handleListShopCustomer(1)}
                  className={Styles.searchBtn}
                >
                  Search
                </button>
              </div>
            </div>
          ) : null}
          <div className={Styles.tableParentContainer}>
            <div className={Styles.TableChildContainer}>
              <table>
                <thead>
                  <tr>
                    <th
                      className={Styles.tableHead + " contentFont text-center"}
                    >
                      S.No
                    </th>
                    <th
                      style={{ width: "18%" }}
                      className={Styles.tableHead + " text-center contentFont"}
                    >
                      Name
                    </th>
                    {/* <th
                      className={Styles.tableHead + " text-center contentFont"}
                    >
                      Email
                    </th> */}
                    <th
                      className={Styles.tableHead + " text-center contentFont"}
                    >
                      Mobile Number
                    </th>
                    <th
                      className={Styles.tableHead + " text-center contentFont"}
                    >
                      City
                    </th>
                    <th
                      className={Styles.tableHead + " text-center contentFont"}
                    >
                      Pincode
                    </th>
                    {/* <th
                      className={Styles.tableHead + " text-center contentFont"}
                    >
                      Address
                    </th> */}
                    <th
                      className={Styles.tableHead + " text-center contentFont"}
                    >
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.items !== undefined &&
                    tableData?.items.length > 0 &&
                    tableData?.items.map((e, index) => (
                      <tr key={e.product_id} style={{ height: "35px" }}>
                        <td className="text-center contentFont">
                          {(tableData.page - 1) * tableData.size + (index + 1)}
                        </td>
                        <OverlayTrigger
                          placement="bottom"
                          trigger={"hover"}
                          overlay={
                            <Popover className={Styles.popover}>
                              {e?.name}
                            </Popover>
                          }
                        >
                          <td className={" contentFont text-center"}>
                            {e.name}
                          </td>
                        </OverlayTrigger>
                        {/* <td className="text-center contentFont">{e?.email}</td> */}
                        <td className="text-center contentFont">
                          {e?.mobile_no}
                        </td>
                        <td className="text-center contentFont">
                          {e?.city_name}
                        </td>
                        <td className="text-center contentFont">
                          {e?.pincode}
                        </td>
                        <td className="text-center contentFont">
                          {e?.created_at}
                        </td>
                      </tr>
                    ))}

                  {tableData?.items?.length === 0 ? (
                    <tr>
                      <td
                        colSpan={5}
                        className="fs-6 text-center text-secondary p-3"
                      >
                        Data Not Found
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>

          <div
            style={{ display: tableData.total <= 10 ? "none" : "flex" }}
            className={Styles.paginationContainer}
          >
            <ReactPaginate
              previousLabel={<img src={LeftArrow} alt="left" />}
              nextLabel={<img src={RightArrow} alt="right" />}
              breakLabel="..."
              pageCount={Math.ceil(tableData.total) / 10}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              onPageChange={({ selected }) =>
                handleListShopCustomer(selected + 1)
              }
              containerClassName={"pagination m-0"}
              pageClassName={"page-item"}
              pageLinkClassName={
                "page-link text-secondary rounded-circle bg-light pt-1 pb-1 ps-2 pe-2 ms-1 me-1 border-0 shadow-none"
              }
              forcePage={page}
              previousClassName={"page-item"}
              previousLinkClassName={
                "page-link border-0 text-dark p-1 rounded-circle bg_orange shadow-none me-1"
              }
              nextClassName={"page-item "}
              nextLinkClassName={
                "page-link border-0 text-dark p-1 bg_orange rounded-circle shadow-none ms-1"
              }
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

import { Navigate, useLocation } from "react-router-dom";
import { getCookie } from "./Utility";
export const AuthPrivateRoute = ({ children }) => {
  const userlog =
    getCookie("userlog") !== "" ? JSON.parse(getCookie("userlog")) : null;

  if (userlog) {
    return <Navigate to="/" />;
  }

  return children;
};

export const StorePrivateRoute = ({ children }) => {
  const userlog =
    getCookie("userlog") !== "" ? JSON.parse(getCookie("userlog")) : null;
  if (!userlog) {
    return <Navigate to="/auth" />;
  }

  return children;
};

export const CustomerPrivateRoute = ({ children }) => {
  console.log("call==");

  const customerlog = sessionStorage.getItem("customerlog");
  const id = getCookie("franchiseid");
  const ID = sessionStorage.getItem("id");
  const { pathname } = useLocation();

  console.log("id", id, "customerlog", customerlog, ID);
  console.log(pathname, "pathname");

  if (id !== "") {
    return <Navigate to={`/kansas_product/shop/${id}`} />;
  }
  if (!customerlog) {
    return <Navigate to="/" />;
  }
  return children;
};

export const CustomerAuthPrivateRoute = ({ children }) => {
  console.log("call==");

  // const customerlog = sessionStorage.getItem("customerlog");
  const id = getCookie("franchiseid");
  const ID = sessionStorage.getItem("id");
  const { pathname } = useLocation();

  console.log("Id==", ID, id);
  console.log(pathname, "pathname");

  if (id === "") {
    console.log("call", ID, id);
    return <Navigate to={`/kansas_product/shop/${id}`} />;
  } else {
    console.log("to", id);

    return <Navigate to={`/kansas_product/allproducts`} />;
  }
};

export const VerifyPrivateRoute = ({ children }) => {
  const verifyStatus = sessionStorage.getItem("otpStatus");
  if (verifyStatus !== null) {
    return <Navigate to="/store" />;
  }
  return children;
};

import React, { useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  Dropdown,
  Modal,
  Offcanvas,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import Styles from "./Header.module.css";
import menuIcon from "../../Assets/loginHomeMenu.svg";
import Profile from "../../Assets/kstore_profile.svg";
import shop from "../../Assets/kstore_shop.svg";
import gif from "../../Assets/kstore.gif";
import dashboardIcon from "../../Assets/dashboard.png";
import language from "../../Assets/translate.png";
import {
  dashboardBalanceService,
  listkansasorderNotification,
  readNotificationList,
  viewProfileService,
} from "../../Services/ApiServices";
import { useAuth } from "../../Services/Context";
import { getCookie, setCookie } from "../../Utility/Utility";
import profile_img from "../../Assets/profile_img.svg";
import key from "../../Assets/key.svg";
import logout from "../../Assets/logout.svg";
import inventoryIcon from "../../Assets/inventory.png";
import paymentIcon from "../../Assets/payment_icon.png";
import billListIcon from "../../Assets/billList.png";
import whiteKey from "../../Assets/whiteKey.png";
import wishlistIcon from "../../Assets/wishlist.png";
import whiteLogout from "../../Assets/whiteLogout.png";
import {
  handleProductCartCount,
  HandleCustomerCartCount,
  ViewProfile,
  handleWishlistCount,
} from "../../Redux/Action";
import LanguageList from "../../Components/Store/Products/LanguageList";
import { Avatar, Badge } from "antd";
import NotificationIcon from "../../Assets/notificationbell.png";
import CommonInfiniteScroll from "../../Components/CommonInputs/CommonInfiniteScroll";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../Loader/Loader";
import moment from "moment";
function Header() {
  let navigate = useNavigate();
  let location = useLocation();
  let dispatch = useDispatch();
  let LogoImage = useSelector((state) => state.logo_details);
  const { user, handleAuthLogin } = useAuth();
  let { count, profile, wishlistCount } = useSelector((state) => state);
  const [openLanguageModal, setopenLanguageModal] = useState(false);
  const [show, setShow] = useState(false);
  const { state } = useLocation();
  const handleClose = () => setShow(false);
  const [showProfileOptions, setShowProfileOptions] = useState(false);
  const [Shownotificationlist, setshowNotificationlist] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const dropdownRef = useRef(null);
  let { access_token } = getCookie("userlog")
    ? JSON.parse(getCookie("userlog"))
    : "";
  // const [page, setPage] = useState(1);
  const size = 10;
  useEffect(() => {
    handleDashboardBalance();
    handleViewProfile();
  }, []);

  const handleDashboardBalance = () => {
    if (location.pathname.includes("/kansas_product")) {
    } else {
      dashboardBalanceService()
        .then((res) => {
          dispatch(
            HandleCustomerCartCount(parseInt(res?.data?.customer_cart_count))
          );
          dispatch(handleProductCartCount(parseInt(res?.data?.cart_count)));
          dispatch(handleWishlistCount(res?.data?.wish_list_count));
        })
        .catch((err) => {
          if (err?.response?.data?.detail) {
            toast.error(err.response.data.detail[0].msg);
          } else {
            toast.error("Something went wrong!!");
          }
        });
    }
  };

  const handleViewProfile = () => {
    if (location.pathname.includes("/kansas_product")) {
    } else {
      viewProfileService()
        .then(({ data }) => {
          setNotificationCount(data);
          dispatch(ViewProfile(data));
        })
        .catch((err) => {
          if (err?.response?.data?.detail) {
            toast.error(err.response.data.detail[0].msg);
          } else {
            toast.error("Something went wrong!!");
          }
        });
    }
  };

  const handleLogout = () => {
    setCookie("userlog", null, null);
    sessionStorage.removeItem("otpStatus");
    setCookie("resetKey", null, null);
    handleAuthLogin(null);
  };

  const [Notificationitems, setNotificationItems] = useState({
    items: [],
    page: 1,
    size: 10,
  });

  // const { page } = Notificationitems;
  const [hasMore, setHasMore] = useState(true);
  const handleGetNotificationList = (page, size, read = false) => {
    listkansasorderNotification(page, size)
      .then((res) => {
        let data = res?.data?.items;

        if (
          data.length < 10 ||
          Math.ceil(res.data.total / 10) === Notificationitems.page
        ) {
          setHasMore(false);
        }
        setNotificationItems((pre) => {
          return {
            ...res.data,
            page: page + 1,
            size: 10,
            items: read ? [...data] : [...pre?.items, ...data],
          };
        });
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong !!", { type: "error" });
        }
      });
  };

  const handleReadNotification = (ID) => {
    setIsLoader(true);
    let formData = new FormData();
    formData.append("notification_id", ID.notification_id);

    readNotificationList(formData)
      .then((res) => {
        handleGetNotificationList(1, 10, true);
        handleViewProfile();
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong !!", { type: "error" });
        }
      })
      .finally(() => {
        setIsLoader(false);
      });
  };
  const divRef = useRef(null);
  useEffect(() => {
    if (access_token && getCookie("userlog") !== "") {
      const interval = setInterval(() => {
        handleGetNotificationList(1, 10);
      }, 180000);

      return () => clearInterval(interval);
    }
    // Clean up interval on component unmount
  }, []);
  useEffect(() => {
    if (getCookie("userlog") !== "") {
      handleGetNotificationList(1, 10);
    }
  }, []);
  // const fetchMoreData = (page, size) => {
  //   console.log(page, size, "sssss");
  //   if (items.length >= 100) {
  //     setHasMore(false);
  //     return;
  //   }
  //   setTimeout(() => {
  //     setItems(items.concat(Array.from({ length: 20 })));
  //   }, 1500);
  // };
  useEffect(() => {
    // Function to handle click events
    const handleClick = (event) => {
      // console.log(
      //   divRef.current,
      //   divRef.current.contains(event.target),
      //   "event"
      // );
      if (divRef.current && divRef.current.contains(event.target)) {
        // setshowNotificationlist(true);
      } else if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setshowNotificationlist((pre) => {
          return pre ? false : pre;
        });
      }
    };
    // Add event listener
    document.addEventListener("click", handleClick);
    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const renderItem = (item, index) => (
    <div
      key={index}
      style={{
        color: "white",
        margin: "10px 0",
        padding: "10px",
      }}
    >
      Item {index + 1}
    </div>
  );

  console.log(LogoImage?.logo, "logo_detailslogo_detailslogo_details");
  console.log(state?.logoDetails?.logo, "statestate");

  return (
    <>
      <Loader isLoader={isLoader} />
      <div className={Styles.parentContainer + " container-fluid p-0"}>
        {show && !location.pathname.includes("/kansas_product") ? (
          <div>
            <Offcanvas
              placement="start w-75"
              className={Styles.mblOverallParentContainer}
              show={show}
              onHide={handleClose}
            >
              <Offcanvas.Header className={Styles.mblHeader}>
                <Offcanvas.Title className={Styles.mblTitle}>
                  <img
                    className={Styles.logo}
                    // onClick={() => navigate("/store")}
                    src={LogoImage?.logo ? LogoImage?.logo : gif}
                    alt="logo"
                  />
                  <img
                    className={Styles.mblLogoImg}
                    onClick={() => {
                      navigate("/store/view_profile");
                      handleClose();
                    }}
                    src={profile?.profile_img || Profile}
                    alt="profile"
                  />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="p-0">
                <div>
                  <div className={Styles.mblMenuContainer}>
                    <Link
                      onClick={handleClose}
                      className={Styles.link}
                      to="/store"
                      style={
                        location.pathname === "/store"
                          ? { backgroundColor: "#ff7b26", fontWeight: "bold" }
                          : { backgroundColor: "black", fontWeight: "normal" }
                      }
                    >
                      <img
                        className={Styles.menuOptions}
                        src={dashboardIcon}
                        alt="dashboard"
                      />
                      <i class="fa-solid fa-thumbs-up"></i> Dashboard
                    </Link>
                  </div>
                  <div className={Styles.mblMenuContainer}>
                    <Link
                      onClick={handleClose}
                      className={Styles.link}
                      to="/store/inventory_list"
                      style={
                        location.pathname.includes("/store/inventory_list")
                          ? { backgroundColor: "#ff7b26", fontWeight: "bold" }
                          : { backgroundColor: "black", fontWeight: "normal" }
                      }
                    >
                      <img
                        className={Styles.menuOptions}
                        src={inventoryIcon}
                        alt="dashboard"
                      />
                      Inventory
                    </Link>
                  </div>
                  <div className={Styles.mblMenuContainer}>
                    <Link
                      onClick={handleClose}
                      className={Styles.link}
                      to="/store/payment_request"
                      style={
                        location.pathname.includes("/store/payment_request")
                          ? { backgroundColor: "#ff7b26", fontWeight: "bold" }
                          : { backgroundColor: "black", fontWeight: "normal" }
                      }
                    >
                      <img
                        className={Styles.menuOptions}
                        src={paymentIcon}
                        alt="dashboard"
                      />
                      Payment Request
                    </Link>
                  </div>
                  <div className={Styles.mblMenuContainer}>
                    <Link
                      onClick={handleClose}
                      className={Styles.link}
                      to="/store/bill_list"
                      style={
                        location.pathname.includes("/store/bill_list")
                          ? {
                              backgroundColor: "#ff7b26",
                              fontWeight: "bold",
                            }
                          : {
                              backgroundColor: "black",
                              fontWeight: "normal",
                            }
                      }
                    >
                      <img
                        className={Styles.menuOptions}
                        src={billListIcon}
                        alt="dashboard"
                      />
                      Bill list
                    </Link>
                  </div>
                  <div className={Styles.mblMenuContainer}>
                    <Link
                      onClick={() => {
                        navigate("/store/change_password");
                        handleClose();
                      }}
                      className={Styles.link}
                      to="/store/change_password"
                      style={
                        location.pathname.includes("/store/change_password")
                          ? {
                              backgroundColor: "#ff7b26",
                              fontWeight: "bold",
                            }
                          : {
                              backgroundColor: "black",
                              fontWeight: "normal",
                            }
                      }
                    >
                      <img
                        className={Styles.menuOptions}
                        src={whiteKey}
                        alt="dashboard"
                      />
                      Change Password
                    </Link>
                  </div>
                  <div className={Styles.mblMenuContainer}>
                    <Link
                      onClick={() => {
                        handleLogout();
                        handleClose();
                      }}
                      className={Styles.link}
                      to="/store/bill_list"
                    >
                      <img
                        className={Styles.menuOptions}
                        src={whiteLogout}
                        alt="dashboard"
                      />
                      Logout
                    </Link>
                  </div>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        ) : null}

        <div
          className={
            !location.pathname.includes("/kansas_product")
              ? Styles.customerOverallContainer
              : Styles.overallContainer
          }
        >
          {!location.pathname.includes("/kansas_product") ? (
            <div className={Styles.menuContainer}>
              <img
                className={Styles.Mblmenu}
                onClick={() => setShow(true)}
                src={menuIcon}
                alt="menu"
              />
            </div>
          ) : null}
          <div className={Styles.logoContainer}>
            <img
              className={Styles.logo}
              onClick={() => {
                if (location.pathname.includes("/kansas_product")) {
                  navigate("/kansas_product/allproducts");
                } else {
                  navigate("/store");
                }
              }}
              src={LogoImage?.logo ? LogoImage?.logo : gif}
              alt="logo"
            />
          </div>
          <div
            style={{
              display: location.pathname.includes("/kansas_product")
                ? "none"
                : null,
            }}
            className={Styles.linkContainer}
          >
            <Link
              style={{
                color: location.pathname === "/store" ? "#ff7b26" : "white",
              }}
              className={Styles.link}
              to="/store"
            >
              Dashboard
            </Link>
            <Link
              className={Styles.link}
              style={{
                color:
                  location.pathname === "/store/inventory_list"
                    ? "#ff7b26"
                    : "white",
              }}
              to="/store/inventory_list"
            >
              Stock List
            </Link>
            <Link
              style={{
                color:
                  location.pathname === "/store/payment_request"
                    ? "#ff7b26"
                    : "white",
              }}
              className={Styles.link}
              to="/store/payment_request"
            >
              Payment Request
            </Link>
            <Link
              style={{
                color:
                  location.pathname === "/store/bill_list"
                    ? "#ff7b26"
                    : "white",
              }}
              className={Styles.link}
              to="/store/bill_list"
            >
              Invoice
            </Link>
          </div>

          {!location.pathname.includes("kansas_product") ? (
            <div className={Styles.LogoImgContainer}>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Notification</Tooltip>}
              >
                <div style={{ marginRight: "10px" }}>
                  <Badge
                    count={
                      notificationCount?.notification_count
                        ? notificationCount?.notification_count
                        : 0
                    }
                  >
                    <div ref={dropdownRef}>
                      <img
                        // onBlur={() => {
                        //   console.log("testtttt");
                        //   setshowNotificationlist(false);
                        // }}
                        onClick={() =>
                          setshowNotificationlist(!Shownotificationlist)
                        }
                        className={Styles.notificationIcon}
                        src={NotificationIcon}
                      />
                    </div>
                  </Badge>
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Language</Tooltip>}
              >
                <div
                  onClick={() => {
                    setopenLanguageModal(true);
                  }}
                  className={Styles.wishlistContainer}
                >
                  <img
                    className={Styles.wishlistLogoImg}
                    src={language}
                    alt="language"
                  />
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Wishlist</Tooltip>}
              >
                <div
                  onClick={() => navigate("/store/wishlist")}
                  className={Styles.wishlistContainer}
                >
                  <img
                    className={Styles.wishlistLogoImg}
                    src={wishlistIcon}
                    alt="notification"
                  />
                  {wishlistCount > 0 ? (
                    <p className={Styles.wishlistNotification}>
                      <span className={Styles.badge}>{wishlistCount}</span>
                    </p>
                  ) : null}
                </div>
              </OverlayTrigger>
              <div className={Styles.parentNotification}>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Customer Viewed List</Tooltip>}
                >
                  <Link to="/store/addtocart" className="d-flex">
                    <img
                      className={Styles.LogoImg}
                      style={{
                        display:
                          location.pathname === "/kansas_product/allproducts"
                            ? "none"
                            : null,
                      }}
                      src={shop}
                      alt="shop"
                    />
                  </Link>
                </OverlayTrigger>

                {count > 0 ? (
                  <div className={Styles.notification}>
                    <span className={Styles.badge}>{count}</span>
                  </div>
                ) : null}
              </div>

              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Profile</Tooltip>}
              >
                <Dropdown className="d-flex">
                  <Dropdown.Toggle
                    className={Styles.profile}
                    id="dropdown-basic"
                  >
                    <img
                      className={Styles.LogoImg2}
                      onClick={() => setShowProfileOptions(!showProfileOptions)}
                      src={profile?.profile_image || Profile}
                      alt="profile"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item className="kstoreFont orange text-center">
                      {user?.username}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={Styles.userOptions + " kstoreFont5"}
                      onClick={() => navigate("/store/view_profile")}
                    >
                      <img
                        className={Styles.userOptionLogo}
                        src={profile_img}
                        alt="profile"
                      />
                      Profile
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => navigate("/store/change_password")}
                      eventKey="change_password"
                      className={Styles.userOptions + " kstoreFont5"}
                    >
                      <img
                        className={Styles.userOptionLogo}
                        src={key}
                        alt="key"
                      />{" "}
                      Change Password
                    </Dropdown.Item>

                    <Dropdown.Item
                      className={Styles.userOptions + " kstoreFont5"}
                      onClick={handleLogout}
                    >
                      <img
                        className={Styles.userOptionLogo}
                        src={logout}
                        alt="logout"
                      />{" "}
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </OverlayTrigger>
            </div>
          ) : null}

          <Modal centered size="md">
            <Modal.Body>
              <iframe src="https://www.youtube.com/embed/GuvAMcsoreI" />
            </Modal.Body>
          </Modal>
        </div>
      </div>
      {Shownotificationlist ? (
        <div
          className={Styles.msgoutline}
          id="scrollableDiv"
          ref={divRef}
          // tabIndex="0"
          // onBlur={() => {
          //   setshowNotificationlist(false);
          // }}
        >
          <CommonInfiniteScroll
            fetchData={(page, size) => handleGetNotificationList(page, size)}
            items={Notificationitems?.items}
            page={Notificationitems.page}
            size={10}
            hasMore={hasMore}
            iddata="scrollableDiv"
          >
            <div>
              {Notificationitems?.items?.map((ele) => {
                return (
                  <>
                    <div
                      className={Styles.msgbox}
                      onClick={(e) => {
                        handleReadNotification(ele);
                        navigate("/store/customerOrderrequestview", {
                          state: ele,
                        });
                        setshowNotificationlist(!Shownotificationlist);
                      }}
                    >
                      <p className={Styles.sender}>{ele.sender_name}</p>
                      <p className={Styles.sendMessage}>{ele?.message}</p>
                      <p className={Styles.senderDate}>
                        {moment(ele?.date_time).format("YYYY-MM-DD HH:mm")}
                      </p>
                    </div>
                  </>
                );
              })}
            </div>
          </CommonInfiniteScroll>
        </div>
      ) : null}
      {openLanguageModal && (
        <LanguageList
          setModal={setopenLanguageModal}
          isShowModal={openLanguageModal}
        />
      )}
    </>
  );
}

export default Header;

import Styles from "./OurServices.module.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import moment from "moment";
import FileSaver from "file-saver";
import toast from "react-hot-toast";
import {
  QrCodeDownloadService,
  UPIQrCodeService,
} from "../../../Services/ApiServices";
import Loader from "../../../SharedComponents/Loader/Loader";

export default function OurServices(props) {
  let navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);

  const handleService = (data) => {
    switch (data) {
      case "products":
        return navigate("/store/products", {
          state: 2,
        });
      case "Purchase Order":
        return navigate("/store/categories", {
          state: 1,
        });
      case "Payment Request":
        return navigate("/store/payment_request");
      case "Stock List":
        return navigate("/store/inventory_list", { state: 1 });
      case "Invoice":
        return navigate("/store/bill_list");
      case "My Order":
        return navigate("/store/myorder");
      case "Complaints":
        return navigate("/store/list_complaint");
      case "e-Brochure":
        return handleQrcode();
      // case "UPI-QR Code":
      //   return handleupiQrCode();
      case "Brochure User":
        return navigate("/store/brochure_user");
      case "Customer Order Request":
        return navigate("/store/customerOrderrequest");
    }
  };

  const handleQrcode = () => {
    setIsLoader(true);
    QrCodeDownloadService()
      .then((res) => {
        let url = res.data.url;
        let urlArr = url.split(".");
        let ext = urlArr[urlArr.length - 1];
        let dateTime =
          moment().format("DD-MM-YYYY") + "," + moment().format("hh:mm:ss a");
        let fileName = dateTime + "." + ext;
        FileSaver.saveAs(url, fileName);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong!!");
        }
      })
      .finally(() => setIsLoader(false));
  };

  const handleupiQrCode = () => {
    setIsLoader(true);
    UPIQrCodeService()
      .then((res) => {
        if (res.data.status === 1) {
          let url = res.data.url;
          let urlArr = url.split(".");
          let ext = urlArr[urlArr.length - 1];
          let dateTime =
            moment().format("DD-MM-YYYY") + "," + moment().format("hh:mm:ss a");
          let fileName = dateTime + "." + ext;
          FileSaver.saveAs(url, fileName);
          console.log(url, "url");
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast.error("Something went wrong!!");
        }
      })
      .finally(() => setIsLoader(false));
  };

  return (
    <>
      <Loader isLoader={isLoader} />
      <div
        onClick={() => handleService(props.data.name)}
        className={Styles.parentContainer}
      >
        <div className={Styles.bg}>
          <img className={Styles.img} src={props.data.img} alt="service" />
        </div>

        <p className={Styles.serviceDescription + " contentFont"}>
          {props.data.name}
        </p>
      </div>
    </>
  );
}

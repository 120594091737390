import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import Styles from "./DetailsPopup.module.css";
import cancelIcon from "../../../Assets/cross.png";
import {
  AddBankAccount,
  EditBankAccount,
  paymentRequestService,
} from "../../../Services/ApiServices";
import Loader from "../../../SharedComponents/Loader/Loader";
import { useEffect } from "react";

export default function MyAccountDetails(props) {
  const {
    handleSubmit,
    resetForm,
    handleChange,
    errors,
    values,
    setFieldValue,
    touched,
    isSubmitting,
    setValues,
  } = useFormik({
    initialValues: {
      bank_name: "",
      account_name: "",
      branch_name: "",
      account_no: "",
      ifsc_code: "",
    },
    validationSchema: Yup.object({
      bank_name: Yup.string().required("Required"),
      account_name: Yup.string().required("Required"),
      branch_name: Yup.string().required("Required"),
      account_no: Yup.string().required("Required"),
      ifsc_code: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      if (props?.bankAccountDetails) {
        handleEditBankDetails(values);
      } else {
        handleAddBankDetails(values);
      }
    },
  });

  const handleAddBankDetails = (data) => {
    let formData = new FormData();
    formData.append("bank_name", data.bank_name);
    formData.append("account_name", data.account_name);
    formData.append("branch_name", data.branch_name);
    formData.append("account_no", data.account_no);
    formData.append("ifsc_code", data.ifsc_code);
    AddBankAccount(formData)
      .then((res) => {
        toast(res.data.msg, { type: "success" });
        props.handleTableData(values);
        props.onHide(false);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast("Something went wrong!!");
        }
      });
  };
  const handleEditBankDetails = (data) => {
    let formData = new FormData();
    formData.append("bank_account_id", props.bankAccountDetails.account_id);
    formData.append("bank_name", data.bank_name);
    formData.append("account_name", data.account_name);
    formData.append("branch_name", data.branch_name);
    formData.append("account_no", data.account_no);
    formData.append("ifsc_code", data.ifsc_code);
    EditBankAccount(formData)
      .then((res) => {
        toast(res.data.msg, { type: "success" });
        props.handleTableData(values);
        props.onHide(false);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast("Something went wrong!!");
        }
      });
  };
  useEffect(() => {
    if (props?.bankAccountDetails) {
      setValues({
        bank_name: props?.bankAccountDetails?.bank_name,
        account_name: props?.bankAccountDetails?.account_name,
        branch_name: props?.bankAccountDetails?.branch_name,
        account_no: props?.bankAccountDetails?.account_no,
        ifsc_code: props?.bankAccountDetails?.ifsc_code,
      });
    }
  }, []);
  return (
    <div className={Styles.parentContainer + " container-fluid"}>
      {isSubmitting && <Loader isLoader={true} />}

      <div className="container">
        <Modal
          size="xl"
          show={props.isOpen}
          onHide={props.onHide}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className={Styles.cancelImgParentContainer}>
              <div>
                <p className="fontBold">
                  {props?.bankAccountDetails ? "Edit Bank" : "Add Bank"}{" "}
                  <span className="orange">Details</span>
                </p>
              </div>
              <div className={Styles.cancelImgContainer}>
                <img
                  onClick={props.onHide}
                  src={cancelIcon}
                  alt="cancel"
                  className={Styles.cancelImg}
                />
              </div>
            </div>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className={Styles.inputParent}>
                <div className={Styles.leftContainer}>
                  <label className="label">
                    Bank Name <span className="text-danger fs-6">*</span>
                  </label>
                  <div>
                    <input
                      className={Styles.input + " contentFont"}
                      type="text"
                      name="bank_name"
                      value={values.bank_name}
                      onChange={handleChange}
                      placeholder="Enter Bank Name"
                    />
                  </div>
                  {touched.bank_name && errors.bank_name ? (
                    <div className={Styles.errMsg + " text-danger"}>
                      {errors.bank_name}
                    </div>
                  ) : null}
                </div>
                <div className={Styles.rightContainer}>
                  <label className="label">
                    Account Name <span className="text-danger fs-6">*</span>
                  </label>
                  <div>
                    <input
                      className={Styles.input + " contentFont"}
                      type="text"
                      name="account_name"
                      onChange={handleChange}
                      value={values.account_name}
                      placeholder="Enter Account Name"
                    />
                  </div>
                  {touched.account_name && errors.account_name ? (
                    <div className={Styles.errMsg + " text-danger"}>
                      {errors.account_name}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={Styles.inputParent}>
                <div className={Styles.leftContainer}>
                  <label className="label">
                    Branch Name <span className="text-danger fs-6">*</span>
                  </label>
                  <div>
                    <input
                      className={Styles.input + " contentFont"}
                      type="text"
                      name="branch_name"
                      onChange={handleChange}
                      value={values.branch_name}
                      placeholder="Enter Branch Name"
                    />
                  </div>
                  {touched.branch_name && errors.branch_name ? (
                    <div className={Styles.errMsg + " text-danger"}>
                      {errors.branch_name}
                    </div>
                  ) : null}
                </div>
                <div className={Styles.rightContainer}>
                  <label className="label">
                    Account Number <span className="text-danger fs-6">*</span>
                  </label>
                  <div>
                    <input
                      onChange={handleChange}
                      className={Styles.input + " contentFont"}
                      type="text"
                      name="account_no"
                      maxLength="16"
                      value={values.account_no}
                      placeholder="Enter Account Number"
                      onKeyPress={(e) => {
                        if (!(e.key === "0" || parseInt(e.key))) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  {touched.account_no && errors.account_no ? (
                    <div className={Styles.errMsg + " text-danger"}>
                      {errors.account_no}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={Styles.inputParent}>
                <div className={Styles.leftContainer}>
                  <label className="label">
                    IFSC Number <span className="text-danger fs-6">*</span>
                  </label>
                  <div>
                    <input
                      className={Styles.input + " contentFont"}
                      type="text"
                      name="ifsc_code"
                      onChange={handleChange}
                      value={values.ifsc_code}
                      placeholder="Enter IFSC Number"
                    />
                  </div>
                  {touched.ifsc_code && errors.ifsc_code ? (
                    <div className={Styles.errMsg + " text-danger"}>
                      {errors.ifsc_code}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={Styles.btnContainer}>
                <button onClick={resetForm} className={Styles.Resetbtn}>
                  Reset
                </button>
                <button onClick={handleSubmit} className={Styles.btn}>
                  Submit
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

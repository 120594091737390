import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  addCustomerWishlistService,
  billingService,
  franchiseOrderService,
} from "../../../Services/ApiServices";
import Loader from "../../../SharedComponents/Loader/Loader";
import Styles from "./Invoice.module.css";
import next from "../../../Assets/next.png";
import paymentSuccess from "../../../Assets/paymentSuccess.png";
import Header from "../../../SharedComponents/Header/Header";
import { setCookie } from "../../../Utility/Utility";
import { HandleCustomerCartCount } from "../../../Redux/Action";
import { Modal } from "react-bootstrap";

export default function Invoice() {
  let { state, pathname } = useLocation();
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let userData = JSON.parse(sessionStorage.getItem("customerlog"));
  const [isLoader, setIsLoader] = useState(false);
  const [showFranchisePriceIds, setShowFranchisePriceIds] = useState([]);
  const [readmoreId, setReadmoreId] = useState([]);
  const [showMsgSentStatus, setShowMsgSentStatus] = useState({
    status: false,
    data: null,
  });

  useEffect(() => {
    if (state?.status === 1) {
      let tempIds = [];
      state.productDetails.items.filter((ele) => {
        if (parseInt(ele.qty) > parseInt(ele.quantity)) {
          tempIds.push(ele.product_id);
          ele?.product_price?.map((element) => {
            if (ele.qty >= element.from_qty && ele.qty <= element.to_qty) {
              ele.franchiseTotalAmount = element.franchise_pricing;
            }
          });
        }
      });
      setShowFranchisePriceIds(tempIds);
    }
  }, []);

  const handleCheckout = (data) => {
    setIsLoader(true);

    if (state?.status === 2) {
      let orderDetails = state?.productDetails?.items?.map((e) => {
        let selectedColorItem = e.product_images?.find((item) => item.isActive);
        return {
          product_id: e?.product_id,
          quantity: e?.qty,
          product_unit: e.selectedUnit ? e.selectedUnit.id : 0,
          product_color: selectedColorItem ? selectedColorItem.id : 0,
          amount:
            state?.status === 2
              ? e?.franchise_price?.toFixed(2)
              : pathname.includes("/kansas_product")
              ? e.selling_price
              : e?.franchise_customer_price?.toFixed(2),
        };
      });

      let formData = new FormData();
      formData.append("order_details", JSON.stringify(orderDetails));

      formData.append("mobile", data.billingMobile);
      formData.append("billing_pincode", parseInt(data.billingPincode));
      formData.append("billing_name", data.billingName);
      formData.append("billing_address", data.billingAddress);
      formData.append("state", parseInt(data.billingState));
      formData.append("city", parseInt(data.billingCity));
      if (state.shipping_status) {
        formData.append("shipping_name", data.shippingName);
        formData.append("shipping_address", data.shippingAddress);
        formData.append("shipping_mobile", data.shippingMobile);
        formData.append("shipping_pincode", parseInt(data.shippingPincode));
        formData.append("shipping_state", parseInt(data.shippingState));
        formData.append("shipping_city", parseInt(data.shippingCity));
      } else {
        formData.append("shipping_name", data.billingName);
        formData.append("shipping_address", data.billingAddress);
        formData.append("shipping_mobile", data.billingMobile);
        formData.append("shipping_pincode", parseInt(data.billingPincode));
        formData.append("shipping_state", parseInt(data.billingState));
        formData.append("shipping_city", parseInt(data.billingCity));
      }
      franchiseOrderService(formData)
        .then((res) => {
          toast(res.data, { type: "success" });
          dispatch(
            HandleCustomerCartCount(parseInt(res.data.customer_cart_count))
          );
          navigate("/store/categories");
          sessionStorage.removeItem("storeDetails");
          sessionStorage.removeItem("billProducts");
        })
        .catch((err) => {
          if (err?.response?.data?.detail) {
            toast.error(err.response.data.detail[0].msg, { type: "error" });
          } else {
            toast.error("Something went wrong !!");
          }
        })
        .finally(() => setIsLoader(false));
    }

    if (state.status === 1) {
      handleBill(data);
    }
  };

  const handleBill = (data) => {
    let orderDetails = state?.productDetails?.items?.map((e) => {
      let selectedColorItem = e.product_images?.find((item) => item.isActive);
      return {
        product_id: e?.product_id,
        quantity: e?.qty,
        product_unit: e.selectedUnit ? e.selectedUnit.id : 0,
        product_color: selectedColorItem ? selectedColorItem.id : 0,
        amount:
          state?.status === 2
            ? e?.franchise_price?.toFixed(2)
            : pathname.includes("/kansas_product")
            ? e.selling_price
            : e?.franchise_customer_price?.toFixed(2),
      };
    });

    let formData = new FormData();
    formData.append("order_details", JSON.stringify(orderDetails));
    if (state?.wishlist_id !== undefined) {
      formData.append(
        "customer_wishlist_products",
        parseInt(state?.wishlist_id)
      );
    }
    formData.append("payment_type", data.paymentStatus);
    if (data.paymentStatus === 4) {
      formData.append("emi", parseInt(data.emiOption));
    }
    if (data.paymentStatus === 5) {
      formData.append("pos_machine", data.posMachine);
    }
    if (data.paymentStatus === 6) {
      formData.append("mobile_send_payment", data.paymentLinkNo);
    }
    if (
      data.OtherEmiOption &&
      data.paymentStatus === 4 &&
      parseInt(data.emiOption) === 4
    ) {
      formData.append("emi_provider", data.OtherEmiOption);
    }
    if (data.paymentStatus === 8) {
      formData.append(
        "payment_customer_name",
        state.billing_details.customer_name
      );
      formData.append(
        "payment_mobile_no",
        state.billing_details.customer_mobile
      );
      formData.append("send_sms", state.send_sms ? 1 : 0);
    }
    formData.append("customer_name", data.billingName);
    formData.append("mobile_no", data.billingMobile);
    formData.append("address", data.billingAddress);
    formData.append("state", data.billingState);
    formData.append("city", data.billingCity);
    formData.append("pincode", data.billingPincode);
    formData.append("gst_no", data.gst_no);
    formData.append("invoice_no", data.invoice_no);
    formData.append("order_from", state?.order_from ? 1 : 0);

    // newBillingService(formData)
    billingService(formData)
      .then((res) => {
        // get orderid from url
        if (
          (data.paymentStatus === 1 ||
            data.paymentStatus === 2 ||
            data.paymentStatus === 8) &&
          res.data.status === 1
        ) {
          window.open(res.data.url, "_self");
        } else if (
          data.paymentStatus === 3 ||
          data.paymentStatus === 4 ||
          data.paymentStatus === 5 ||
          data.paymentStatus === 6 ||
          data.paymentStatus === 7
        ) {
          toast("Success", { type: "success" });
          navigate("/store/bill_list");
        } else if (data.paymentStatus === 8 && res.data.status === 2) {
          setShowMsgSentStatus({
            data: res.data.msg,
            status: true,
          });
        }

        dispatch(
          HandleCustomerCartCount(parseInt(res.data.customer_cart_count))
        );
        sessionStorage.removeItem("storeDetails");
        sessionStorage.removeItem("billProducts");
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg);
        } else {
          toast.error("Something went wrong !!");
        }
      })
      .finally(() => setIsLoader(false));
  };

  const handleFranchiseTotal = () => {
    if (state?.productDetails?.length === 0) {
      return 0;
    }
    let franchiseAmt = [];
    state?.productDetails?.items?.map((ele) => {
      if (showFranchisePriceIds?.includes(ele?.product_id)) {
        franchiseAmt = [...franchiseAmt, ele?.franchiseTotalAmount];
      }
    });
    const franchiseTotalAmount = franchiseAmt?.reduce(
      (previousValue, currentValue) => previousValue + currentValue,

      0
    );

    return franchiseTotalAmount;
  };

  const handleCartTotal = () => {
    if (state?.productDetails?.length === 0) {
      return 0;
    }
    const totalAmount = state?.productDetails?.items?.reduce(
      (previousValue, currentValue) => {
        if (state.status == 2) {
          return previousValue + currentValue.franchiseTotalAmount;
        } else {
          return previousValue + currentValue.total_amount;
        }
      },
      0
    );
    return totalAmount;
  };

  const handleSubmit = () => {
    setIsLoader(true);
    let lat = {};
    if (sessionStorage.getItem("location_details") !== null) {
      lat.location = JSON.parse(sessionStorage.getItem("location_details"));
    }
    let product_id = state?.productDetails?.items?.map((e) => e.product_id);
    let quantity = state?.productDetails?.items?.map((e) => e.qty);
    let colorCode = state?.productDetails?.items?.map((e) => {
      let selectedColorItem = e.product_images.find((item) => item.isActive);
      return selectedColorItem ? selectedColorItem.code : "";
    });
    let unit = state?.productDetails?.items?.map((e) => {
      return e.selectedUnit ? e.selectedUnit.id : "";
    });
    let orderDetails = state?.productDetails?.items?.map((e) => {
      let selectedColorItem = e.product_images?.find((item) => item.isActive);
      return {
        product_id: e?.product_id,
        quantity: e?.qty,
        product_unit: e.selectedUnit ? e.selectedUnit.id : 0,
        product_color: selectedColorItem ? selectedColorItem.id : 0,
      };
    });
    let formData = new FormData();
    formData.append("customer_name", userData.name);
    formData.append("mobile_no", userData.mblNumber);
    formData.append("latitude", lat?.location?.lat);
    formData.append("longitude", lat?.location?.lng);
    formData.append("email", userData.email);
    formData.append("franchise_id", userData.id);
    formData.append("order_details", JSON.stringify(orderDetails));
    // formData.append("product_id", "[" + product_id + "]");
    // formData.append("quantity", "[" + quantity + "]");
    // formData.append("product_color", "[" + colorCode + "]");
    // formData.append("product_unit", "[" + unit + "]");
    formData.append("mobile", state.billing_details.billingMobile);
    formData.append(
      "billing_pincode",
      parseInt(state.billing_details.billingPincode)
    );
    formData.append("email", state.billing_details.billingEmail);
    formData.append("billing_address", state.billing_details.billingAddress);
    formData.append("state", parseInt(state.billing_details.billingState));
    formData.append("city", parseInt(state.billing_details.billingCity));
    if (state.shipping_status) {
      formData.append("customer_name", state.billing_details.billingName);

      formData.append("shipping_name", state.billing_details.shippingName);

      formData.append(
        "shipping_address",
        state.billing_details.shippingAddress
      );

      formData.append("shipping_mobile", state.billing_details.shippingMobile);

      formData.append(
        "shipping_pincode",
        parseInt(state.billing_details.shippingPincode)
      );

      formData.append(
        "shipping_state",
        parseInt(state.billing_details.shippingState)
      );

      formData.append(
        "shipping_city",
        parseInt(state.billing_details.shippingCity)
      );
      if (state?.billing_details?.shippingEmail) {
        formData.append(
          "shipping_email",
          parseInt(state.billing_details.shippingEmail)
        );
      }
    } else {
      formData.append("billing_name", state.billing_details.billingName);

      formData.append("shipping_name", state.billing_details.billingName);

      formData.append("shipping_address", state.billing_details.billingAddress);

      formData.append("shipping_mobile", state.billing_details.billingMobile);

      formData.append(
        "shipping_pincode",
        parseInt(state.billing_details.billingPincode)
      );

      formData.append(
        "shipping_state",
        parseInt(state.billing_details.billingState)
      );

      formData.append(
        "shipping_city",
        parseInt(state.billing_details.billingCity)
      );
      if (state?.billing_details?.billingEmail) {
        formData.append("shipping_email", state.billing_details.billingEmail);
      }
    }
    addCustomerWishlistService(formData)
      .then((res) => {
        navigate("/kansas_product/success");
        const id = sessionStorage.getItem("id");
        sessionStorage.removeItem("customerlog");
        sessionStorage.removeItem("custmerDetails");
        sessionStorage.removeItem("kansas_product");
        sessionStorage.removeItem("location_details");
        sessionStorage.removeItem("customer_cart_list");
        setCookie("franchiseid", id, 0.5);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast.error(err.response.data.detail[0].msg);
        } else {
          toast.error("Something went wrong!!", { type: "error" });
        }
      })
      .finally(() => setIsLoader(false));
  };

  return (
    <>
      {pathname.includes("/kansas_product") ? <Header /> : null}
      <div className="container-fluid p-0">
        <Loader isLoader={isLoader} />
        {showMsgSentStatus.status ? (
          <Modal
            centered
            size="md"
            show={showMsgSentStatus.status}
            onHide={() =>
              setShowMsgSentStatus({
                data: null,
                status: false,
              })
            }
          >
            <Modal.Body>
              <div>
                <div className="text-center">
                  <img src={paymentSuccess} alt="success" />
                </div>
                <p className={Styles.paymentMsg}>{showMsgSentStatus.data}</p>
              </div>
            </Modal.Body>
          </Modal>
        ) : null}
        <div className="container">
          <p className="fontBold text-center mt-3">
            Invoice <span className="orange">Details</span>
          </p>
          <div className={Styles.parentDetails}>
            <div className={Styles.leftDetails}>
              <div>
                <p className="fontBold orange">Billing Address : </p>
                <p className={Styles.details + " fw-bold"}>
                  {state?.billing_details?.billingName}
                </p>
                <p className={Styles.details}>
                  {state?.billing_details?.billingAddress}
                </p>
                <p className={Styles.details}>
                  {state?.billing_details?.city_name}
                </p>
                <p className={Styles.details}>
                  {state?.billing_details?.state_name}
                </p>
                <p className={Styles.details}>
                  {state?.billing_details?.billingPincode}
                </p>
                <p className={Styles.details}>
                  <span>Email : </span>
                  {state?.billing_details?.billingEmail}
                </p>
                <p className={Styles.details}>
                  <span>Phone : </span>
                  {state?.billing_details?.billingMobile}
                </p>
              </div>
            </div>
            <div className={Styles.rightDetails}>
              <p className="fontBold orange">Shipping Address : </p>
              <p className={Styles.shippingDetails + " fw-bold"}>
                {!state?.shipping_status
                  ? state?.billing_details?.billingName
                  : state?.billing_details?.shippingName}
              </p>
              <p className={Styles.shippingDetails}>
                {!state.shipping_status
                  ? state?.billing_details?.billingAddress
                  : state?.billing_details?.shippingAddress}
              </p>
              <p className={Styles.shippingDetails}>
                {!state?.shipping_status
                  ? state?.billing_details?.city_name
                  : state?.billing_details?.shipping_cityname}
              </p>
              <p className={Styles.shippingDetails}>
                {!state?.shipping_status
                  ? state?.billing_details?.state_name
                  : state.billing_details.shipping_statename}
              </p>
              <p className={Styles.shippingDetails}>
                {!state?.shipping_status
                  ? state?.billing_details?.billingPincode
                  : state?.billing_details?.shippingPincode}
              </p>
              <p className={Styles.shippingDetails}>
                <span>Email : </span>
                {!state?.shipping_status
                  ? state?.billing_details?.billingEmail
                  : state?.billing_details?.shippingEmail}
              </p>
              <p className={Styles.shippingDetails}>
                <span>Phone : </span>
                {!state?.shipping_status
                  ? state?.billing_details?.billingMobile
                  : state?.billing_details?.shippingMobile}
              </p>
            </div>

            {!pathname.includes("/kansas_product") ? (
              <div className={Styles.paymentMode}>
                {state.status === 2 ? (
                  <p>Payment Mode : Wallet</p>
                ) : (
                  <p>
                    Payment Mode :{" "}
                    {state.billing_details.paymentStatus === 1
                      ? "UPI"
                      : state.billing_details.paymentStatus === 2
                      ? "CREDIT / DEBIT / NET BANKING"
                      : state.billing_details.paymentStatus === 3
                      ? "CASH"
                      : state.billing_details.paymentStatus === 4
                      ? "EMI"
                      : state.billing_details.paymentStatus === 5
                      ? "POS Machine / UPI / CARD"
                      : state.billing_details.paymentStatus === 6
                      ? "Payment Link"
                      : state.billing_details.paymentStatus === 8
                      ? "RAZORPAY"
                      : null}
                  </p>
                )}

                {state.billing_details.paymentStatus === 4 ? (
                  <p>
                    EMI Provider : {state?.billing_details?.emiProvidername}
                  </p>
                ) : null}
                {state.billing_details.paymentStatus === 5 ? (
                  <p>Transaction ID : {state.billing_details.posMachine}</p>
                ) : null}
                {state.billing_details.paymentStatus === 6 ? (
                  <p>Payment Link No : {state.billing_details.paymentLinkNo}</p>
                ) : null}
              </div>
            ) : null}
          </div>

          <div className={Styles.tableParent} style={{ marginBottom: 7 }}>
            <div className={Styles.swichContainer}>
              <p className="fontBold">
                Product <span className="orange">Details</span>{" "}
              </p>
            </div>

            {/* ONLY FOR MOBILE */}
            <div className={Styles.mobileCart + " d-block d-sm-none"}>
              {state?.productDetails?.items?.map((ele) => (
                <div className={Styles.item}>
                  <p className={Styles.title}>{ele.product_name}</p>

                  {(ele.colorItem || ele.selectedUnit) && (
                    <div className={Styles.unitColor}>
                      {ele.selectedUnit && (
                        <p className={Styles.title}>
                          Unit : {ele.selectedUnit.unit_id}
                        </p>
                      )}
                      {ele.colorItem && (
                        <p className={Styles.title}>
                          Color : {ele.colorItem.name}
                        </p>
                      )}
                    </div>
                  )}
                  <div className={Styles.quantity}>
                    Qty: {ele?.qty} | ₹{" "}
                    {state?.status === 2
                      ? ele?.franchise_price?.toFixed(2)
                      : pathname.includes("/kansas_product")
                      ? ele.selling_price
                      : ele?.franchise_customer_price?.toFixed(2)}
                  </div>
                  <p className={Styles.price}>
                    Total:&nbsp; <span class="currency_symbol"> ₹</span>
                    {state?.status === 2
                      ? ele?.franchiseTotalAmount?.toFixed(2)
                      : ele?.total_amount?.toFixed(2)}
                  </p>
                </div>
              ))}

              <p className={Styles.toatalPrice}>
                Total: <span>₹ {handleCartTotal()?.toFixed(2)}</span>
              </p>
            </div>

            {/* ONLY FOR MOBILE END */}

            <div className={Styles.ParentTable + " d-none d-sm-block"}>
              <div className={Styles.childTable}>
                <table>
                  <thead>
                    <tr>
                      <th className={Styles.th + " text-center"}>S.No</th>
                      <th className={Styles.cartProductName + " text-center"}>
                        Product Name
                      </th>
                      <th className={Styles.th + " text-center"}>Color</th>
                      <th className={Styles.th + " text-center"}>Unit</th>
                      <th
                        style={{
                          display: pathname.includes("kansas_product")
                            ? "none"
                            : "block",
                        }}
                        className={Styles.th + " text-center"}
                      >
                        Stock
                      </th>
                      <th className={Styles.th + " text-center"}>Quantity</th>
                      <th className={Styles.th + " text-center"}>Price</th>
                      <th
                        style={{
                          display:
                            state.status === 2 ||
                            pathname.includes("kansas_product") ||
                            showFranchisePriceIds.length === 0
                              ? "none"
                              : null,
                        }}
                        className={Styles.th + " text-center"}
                      >
                        Franchise Price
                      </th>
                      <th className={Styles.cartList + " text-center"}>
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {state?.productDetails?.items?.map((e, index) => (
                      <>
                        <tr key={e?.product_id}>
                          <td
                            className={Styles.th + " text-center contentFont"}
                          >
                            {index + 1}
                          </td>
                          <td
                            className={
                              Styles.productName + " text-center contentFont"
                            }
                          >
                            {readmoreId.includes(e?.product_id)
                              ? e?.product_name
                              : e?.product_name?.substring(0, 37)}
                            {e?.product_name?.length > 37 &&
                            !readmoreId.includes(e.product_id)
                              ? "..."
                              : null}
                            <span
                              style={{
                                display:
                                  e?.product_name?.length < 37
                                    ? "none"
                                    : "block",
                              }}
                              className={Styles.readmore + " orange"}
                              onClick={() => {
                                if (readmoreId.includes(e?.product_id)) {
                                  let filterData = readmoreId.filter(
                                    (filterId) => filterId !== e?.product_id
                                  );
                                  setReadmoreId(filterData);
                                } else {
                                  setReadmoreId([...readmoreId, e?.product_id]);
                                }
                              }}
                            >
                              {readmoreId.includes(e.product_id)
                                ? "Show Less"
                                : "Read More"}
                            </span>
                          </td>
                          <td
                            className={Styles.th + " text-center contentFont"}
                          >
                            {e.colorItem ? e.colorItem.name : ""}
                          </td>
                          <td
                            className={Styles.th + " text-center contentFont"}
                          >
                            {e.selectedUnit ? e.selectedUnit.unit_id : "-"}
                          </td>

                          <td
                            style={{
                              color:
                                e?.quantity > parseInt(e?.qty)
                                  ? "green"
                                  : "red",
                              display: pathname.includes("kansas_product")
                                ? "none"
                                : null,
                            }}
                            className={Styles.th + " text-center contentFont"}
                          >
                            {e?.quantity > parseInt(e?.qty)
                              ? "In Stock"
                              : "Out of stock " +
                                "(" +
                                (e?.qty - e?.quantity) +
                                ")"}
                          </td>
                          <td
                            className={Styles.th + " text-center contentFont"}
                          >
                            {e?.qty}
                          </td>
                          <td
                            className={
                              Styles.price + " text-center contentFont"
                            }
                          >
                            <p className={Styles.priceSymbol}>₹</p>
                            <p className={Styles.priceAmt}>
                              {state?.status === 2
                                ? e?.franchise_price?.toFixed(2)
                                : pathname.includes("/kansas_product")
                                ? e.selling_price
                                : e?.franchise_customer_price?.toFixed(2)}
                            </p>
                          </td>
                          <td
                            style={{
                              display:
                                state.status === 2 ||
                                pathname.includes("kansas_product") ||
                                showFranchisePriceIds.length === 0
                                  ? "none"
                                  : null,
                            }}
                            className="text-center contentFont"
                          >
                            {parseInt(e.qty) > parseInt(e.quantity)
                              ? "₹" + e?.franchiseTotalAmount?.toFixed(2)
                              : null}
                          </td>
                          <td className=" text-center contentFont">
                            <div className={Styles.price}>
                              <p className={Styles.priceSymbol}>₹</p>
                              <p className={Styles.priceAmt}>
                                {state?.status === 2
                                  ? e?.franchiseTotalAmount?.toFixed(2)
                                  : e?.total_amount?.toFixed(2)}
                              </p>
                            </div>
                          </td>
                        </tr>
                      </>
                    ))}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td
                        style={{
                          display: pathname.includes("kansas_product")
                            ? "none"
                            : "block",
                        }}
                      ></td>
                      <td className="text-center fw-bold orange">Total</td>
                      <td
                        style={{
                          display:
                            showFranchisePriceIds.length > 0 ? "block" : "none",
                          height: "44px",
                        }}
                        className={Styles.th + " text-center fw-bold"}
                      >
                        ₹{handleFranchiseTotal().toFixed(2)}
                      </td>
                      <td className={Styles.th + " text-center fw-bold"}>
                        ₹{handleCartTotal()?.toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <p className="disclaimer">
            ***Some products will be deliver only to the FRANCHISE outlet***
          </p>

          <div className={Styles.btnParent}>
            <button onClick={() => navigate(-1)} className={Styles.PreviousBtn}>
              <img className={Styles.nextIcon} src={next} alt="next" />
              Previous
            </button>
            <button
              onClick={() =>
                pathname.includes("kansas_product")
                  ? handleSubmit()
                  : handleCheckout(state.billing_details)
              }
              className={Styles.btn}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

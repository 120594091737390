import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import toast from "react-hot-toast";
import signupImg from "../../Assets/kstore2.gif";
import Styles from "./Signup.module.css";
import { setCookie } from "../../Utility/Utility";
import ReactSelect from "react-select";
import {
  cityService,
  getDetails,
  shopCustomersFormService,
  stateService,
} from "../../Services/ApiServices";
import { Toast } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { handleLogoDetails } from "../../Redux/Action";

const phoneExp = /^[6,7,8,9]{1}[0-9]{9}$/;
const pinCodeExp = /^6[0-9]{5}$/;
let EMAIL_REGEX = new RegExp(
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
function SignUp() {
  let navigate = useNavigate();
  let param = useParams();
  let location = useLocation();
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [logoDetails, setLogoDetails] = useState({
    name: "",
    logo: null,
  });

  const dispatch = useDispatch();
  const ID = sessionStorage.getItem("id");

  console.log(ID, "ID");

  const isproduct = sessionStorage.getItem("isproduct");

  console.log(isproduct, "isproduct");

  useEffect(() => {
    if (isproduct) {
      navigate("/kansas_product/allproducts");
    }
  }, [isproduct]);

  const customStyles = {
    control: () => ({
      // none of react-select's styles are passed to <Control />
      backgroundColor: "black",
      border: "none",
      width: "100%",
      ":hover": {
        borderColor: "black",
      },
    }),
    indicatorsContainer: () => ({
      display: "none",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "white",
    }),
    input: (provided, state) => ({
      ...provided,
      color: "white",
    }),
  };
  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      mblNumber: "",
      address: "",
      state: "",
      city: "",
      pincode: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      // email: Yup.string()
      //   .matches(EMAIL_REGEX, "Enter Valid email address")
      //   .email("Invalid email address")
      //   .required("Required"),
      mblNumber: Yup.string()
        .min(10, "Mobile number must 10 character")
        .matches(phoneExp, "Mobile number must start with 9 or 8 or 7 or 6 ")
        .required("Required"),
      // address: Yup.string().required("Required"),
      city: Yup.string().required("Required"),
      pincode: Yup.string()
        .min(6, "Pincode must be 6 character")
        .matches(pinCodeExp, "Pincode must start with 6")
        .required("Required"),
    }),
    onSubmit: (values) => {
      setIsLoader(true);
      handleShopCustomerForm();
    },
  });

  useEffect(() => {
    if (sessionStorage.getItem("customerlog")) {
      let userDetails = JSON.parse(sessionStorage.getItem("customerlog"));
      setValues({
        ...values,
        name: userDetails.name,
        email: userDetails.email,
        mblNumber: userDetails.mblNumber,
        address: userDetails.address,
      });
    }
  }, []);
  const handleShopCustomerForm = () => {
    let formData = new FormData();
    formData.append("franchise_id", param.id);
    formData.append("name", values.name);
    formData.append("mobile_no", values.mblNumber);
    // formData.append("email", values.email);
    // formData.append("address", values.address);
    formData.append("city_id", values.city);
    formData.append("pincode", values.pincode);
    shopCustomersFormService(formData)
      .then((res) => {
        // console.log("CALL");

        handleDetails();
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const handleDetails = () => {
    console.log("set==");

    setCookie("franchiseid", null, null);
    sessionStorage.setItem(
      "customerlog",
      JSON.stringify({ ...values, id: param.id })
    );
    sessionStorage.setItem("id", param.id);
    navigate("/kansas_product/allproducts", {
      state: { logoDetails: logoDetails, isproduct: true },
    });
  };

  const getLogoDetails = () => {
    // const url = location.pathname;
    // const afterShop = url.split("shop/")[1];
    // console.log(afterShop, "afterShopppp");
    let formData = new FormData();
    formData.append("key_str", param.id);
    getDetails(formData)
      .then((res) => {
        if (res.data.status) {
          setLogoDetails(res.data);
          setShowIcon(true);
          dispatch(handleLogoDetails(res.data));
          console.log(res.data, "Responseeee");
        } else {
          setShowIcon(true);
          console.log(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const handleStateData = () => {
  //   stateService()
  //     .then((res) => setState(res.data))
  //     .catch((err) => console.log(err));
  // };
  const handleCity = (data) => {
    let cityIds = [31, 35]; //
    let formData = new FormData();
    formData.append("state_id", cityIds.join(","));
    cityService(formData)
      .then((res) => setCity(res.data))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getLogoDetails();
    handleCity();
  }, []);

  return (
    <div className={Styles.bgContainer + " container-fluid"}>
      <div className={Styles.bg}></div>
      {isLoader ? (
        <div className={Styles.loaderParent}>
          <div className={Styles.loader}></div>
        </div>
      ) : null}
      <div className={Styles.loginContainer}>
        <div className={Styles.loginLeftContainer}>
          {showIcon ? (
            <img
              className={Styles.loginImg}
              src={logoDetails?.logo ? logoDetails?.logo : signupImg}
              alt="login"
            />
          ) : null}
        </div>

        <div className={Styles.loginRightContainer}>
          <div>
            <h4
              className="kstoreFont"
              style={{ fontWeight: "bold", fontSize: "16px" }}
            >
              Welcome to{" "}
              <span className="orange">
                {logoDetails?.name ? logoDetails?.name : " KSTORe"}
              </span>
            </h4>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className={Styles.labelName + " mt-4"}>
                <label>Name</label>
                <div className={Styles.inputContainer}>
                  <input
                    className={Styles.emailInput}
                    type="text"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    placeholder="Enter Name"
                  />
                </div>
              </div>
              {touched.name && errors.name ? (
                <div className={Styles.errorMsg + " text-danger"}>
                  {errors.name}
                </div>
              ) : null}
              {/* <div className={Styles.labelName + " mt-3"}>
                <label>e-Mail</label>
                <div className={Styles.inputContainer}>
                  <input
                    className={Styles.emailInput}
                    type="text"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    placeholder="Enter Your e-Mail"
                  />
                </div>
              </div>
              {touched.email && errors.email ? (
                <div className={Styles.errorMsg + " text-danger"}>
                  {errors.email}
                </div>
              ) : null} */}

              <div className={Styles.labelName + " mt-3"}>
                <label>Mobile Number</label>
                <div className={Styles.inputContainer}>
                  <input
                    className={Styles.emailInput}
                    type="text"
                    inputMode="numeric"
                    name="mblNumber"
                    maxLength={10}
                    onKeyDown={(e) => {
                      if (
                        !(
                          parseInt(e.key) === 0 ||
                          parseInt(e.key) ||
                          e.key === "Backspace"
                        )
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                    value={values.mblNumber}
                    placeholder="Enter Mobile Number"
                  />
                </div>
              </div>
              {touched.mblNumber && errors.mblNumber ? (
                <div className={Styles.errorMsg + " text-danger"}>
                  {errors.mblNumber}
                </div>
              ) : null}

              {/* <div className={Styles.labelName + " mt-3"}>
                <label>Address</label>
                <div className={Styles.inputContainer}>
                  <input
                    className={Styles.emailInput}
                    type="text"
                    name="address"
                    onChange={handleChange}
                    value={values.address}
                    placeholder="Enter Address"
                  />
                </div>
              </div>
              {touched.address && errors.address ? (
                <div className={Styles.errorMsg + " text-danger"}>
                  {errors.address}
                </div>
              ) : null} */}
              <div className={Styles.labelName + " mt-3"}>
                <label>City</label>
                <div className={Styles.inputContainer}>
                  <ReactSelect
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Select City..."
                    name="color"
                    // defaultInputValue={selectRef.city}
                    value={city.find((e) => e.cities_id === values.city)}
                    isClearable={false}
                    // styles={customStyles}
                    options={city}
                    getOptionLabel={(e) => e.cities_name}
                    getOptionValue={(e) => e.cities_name}
                    onChange={(e) => {
                      // setSelectRef({ ...selectRef, city: e.cities_name });
                      setFieldValue("city", e.cities_id);
                    }}
                    key={values.city !== "" ? Math.random() : "123"}
                  />
                </div>
                {touched.city && errors.city ? (
                  <div className={Styles.errorMsg + " text-danger"}>
                    {errors.city}
                  </div>
                ) : null}
              </div>
              <div className={Styles.labelName + " mt-3"}>
                <label>Pincode</label>
                <div className={Styles.inputContainer}>
                  <input
                    className={Styles.emailInput}
                    type="text"
                    inputMode="numeric"
                    name="pincode"
                    maxLength={6}
                    onKeyDown={(e) => {
                      if (
                        !(
                          parseInt(e.key) === 0 ||
                          parseInt(e.key) ||
                          e.key === "Backspace"
                        )
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                    value={values.pincode}
                    placeholder="Enter Pincode"
                  />
                </div>
              </div>
              {touched.pincode && errors.pincode ? (
                <div className={Styles.errorMsg + " text-danger"}>
                  {errors.pincode}
                </div>
              ) : null}

              <div className="text-center">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className={Styles.loginBtn + " mt-4"}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;

import Styles from "./Success.module.css";
import success from "../../../Assets/customer_success.png";
import Header from "../../../SharedComponents/Header/Header";
import { useEffect } from "react";

export default function Success() {
  const isproduct = sessionStorage.getItem("isproduct");
  console.log();

  useEffect(() => {
    console.log("ispro");

    sessionStorage.setItem("isproduct", false);
  }, [isproduct]);

  return (
    <>
      <Header />
      <div className={Styles.overallContainer + " container-fluid p-0"}>
        <div className={Styles.childContainer}>
          <img className={Styles.img} src={success} alt="success" />
          <p className={Styles.success}>Thanks for shopping with us!!!</p>
        </div>
      </div>
    </>
  );
}

import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";
import Styles from "./PaymentMode.module.css";
import { paymentRequestService } from "../../../Services/ApiServices";
import cancel from "../../../Assets/cross.png";

export default function PaymentMode(props) {
  const { handleSubmit, values, errors, touched, handleChange, setFieldValue } =
    useFormik({
      initialValues: {
        payname: "",
        amt: "",
        paymentType: "6",
        remarks: "",
      },
      validationSchema: Yup.object({
        payname: Yup.string().required("Required"),
        amt: Yup.string().required("Required"),
        paymentType: Yup.string().required("Required"),
      }),
      validate: () => {
        let err = {};
        if (values.amt === "") {
          err.amt = "Required";
        }
        return err;
      },
      onSubmit: (values) => {
        handlePayment(values);
      },
    });

  const handlePayment = (data) => {
    let formData = new FormData();
    formData.append("amount", data.amt);
    formData.append("payment_mode", parseInt(data.paymentType));
    formData.append("payee_name", data.payname);
    formData.append("remarks", data.remarks);
    paymentRequestService(formData)
      .then((res) => {
        if (
          parseInt(data.paymentType) === 5 ||
          parseInt(data.paymentType) === 4 ||
          parseInt(data.paymentType) === 6
        ) {
          window.open(res.data.url, "_self");
        }
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          toast(err.response.data.detail[0].msg, { type: "error" });
        } else {
          toast("Something went wrong!!");
        }
      });
  };
  return (
    <Modal centered show={props.isShow} onHide={props.onHide} size="md">
      <Modal.Body>
        <div onClick={props.onHide} className={Styles.cancelParent}>
          <div className={Styles.cancelContainer}>
            <img className={Styles.cancelImg} src={cancel} alt="cancel" />
          </div>
        </div>
        <div className={Styles.childContainer}>
          <p className="fontBold pt-2 pb-2">Payment</p>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className={Styles.inputContainer}>
              <label className="label pb-1">
                Payment Mode Amount <span className="text-danger">*</span>
              </label>
              <div className="d-flex">
                {/* <div>
                  <input
                    onChange={handleChange}
                    value="5"
                    checked={parseInt(values.paymentType) === 5}
                    type="radio"
                    name="paymentType"
                  />
                  <label
                    onClick={() => setFieldValue("paymentType", 5)}
                    className="label ps-1"
                  >
                    UPI
                  </label>
                </div> */}
                {/* <div className="ps-4">
                  <input
                    onChange={handleChange}
                    value="4"
                    name="paymentType"
                    checked={parseInt(values.paymentType) === 4}
                    type="radio"
                  />
                  <label
                    onClick={() => setFieldValue("paymentType", 4)}
                    className="label ps-1"
                  >
                    CREDIT / DEBIT
                  </label>
                </div> */}
                <div className="ps-0">
                  <input
                    onChange={handleChange}
                    value="6"
                    name="paymentType"
                    checked={parseInt(values.paymentType) === 6}
                    type="radio"
                  />
                  <label
                    onClick={() => setFieldValue("paymentType", 6)}
                    className="label ps-1"
                  >
                    Online Payment
                  </label>
                </div>
              </div>
            </div>
            {touched.paymentType && errors.paymentType ? (
              <div className={Styles.errMsg + " text-danger"}>
                {errors.paymentType}
              </div>
            ) : null}
            <div className={Styles.inputContainer}>
              <label className="label pb-1">
                Payname <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="payname"
                placeholder="Enter Name"
                value={values.payname}
                onChange={(e) => setFieldValue("payname", e.target.value)}
                className={Styles.input}
              />
            </div>
            {touched.payname && errors.payname ? (
              <div className={Styles.errMsg + " text-danger"}>
                {errors.payname}
              </div>
            ) : null}
            <div className={Styles.inputContainer}>
              <label className="label pb-1">
                Amount <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="amt"
                placeholder="Enter Amount"
                value={values.amt}
                onKeyPress={(e) => {
                  if (!(e.key === "0" || parseInt(e.key))) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => setFieldValue("amt", e.target.value)}
                className={Styles.input}
              />
            </div>
            {touched.amt && errors.amt ? (
              <div className={Styles.errMsg + " text-danger"}>{errors.amt}</div>
            ) : null}
            <div className={Styles.inputContainer}>
              <label className="label pb-1">Remarks</label>
              <textarea
                type="text"
                placeholder="Enter Your Comment Here"
                name="remarks"
                value={values.remarks}
                onChange={handleChange}
                className={Styles.remark}
              />
            </div>
            <div className={Styles.btnContainer}>
              <button onClick={handleSubmit} className={Styles.btn}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
